<template>
  <static-page title="BujinTV Privacy Notice">
    <p>Last Updated: 17 October 2020</p>

    <p>BujinTV is a service run by Bujin Media LLP.</p>

    <p>
      BujinTV is committed to protecting your information in compliance with the
      latest data protection and privacy laws. We also want to be transparent
      about what information we store about you, how we use it, and how you can
      control what we do with this information.
    </p>

    <h2>WHAT INFORMATION WE MIGHT STORE ABOUT YOU</h2>

    <ul>
      <li>
        Data you give us: We store information from you when you become a
        BujinTV subscriber, use our service or when you contact customer
        services. This will include your email address and password, billing
        address, payment card details and any other information you volunteer to
        us. We’ll only use this supplementary information to deal with your
        query or for the purposes explained to you at the time the information
        was stored. Please note that if you contact us to assist you, to help
        protect your privacy we may need to verify your identity before we can
        assist you.
      </li>
      <li>
        Data we store automatically: When you use the BujinTV service, we’ll
        store technical information about your use and browsing of our website,
        our apps, our advertising, and information about the device you’re using
        to access the services. For example, we receive your IP address when
        your device talks to our servers so we can send content to the right
        device.
      </li>
      <li>
        We also store information about the model and version of the device,
        browser and operating system you’re using; data about how you use the
        service and what you view; your device’s network and connections; your
        network and/or GPS location. We also store unique IDs from your device –
        like randomly generated cookie IDs or your mobile device’s Ad ID (a
        value assigned by the operating system, which you can reset or limit).
        To store this information, we may use cookies and other tracking
        technologies; you can find out more about these in the relevant section
        of this policy below.
      </li>
      <li>
        Information from other sources: We may also acquire information about
        you from third parties, for example from publicly available government
        databases, from specialist data providers (which may include demographic
        and interest based information about you), or from BujinTV partners who
        make our service available on their devices. We treat all information we
        acquire about you in accordance with this Privacy Notice. From time to
        time we may also store information about you by asking you to fill in a
        survey or questionnaire.
      </li>
    </ul>

    <h2>HOW WE USE THIS INFORMATION AND OUR LEGAL BASIS FOR PROCESSING</h2>

    <p>
      We aim to use your information in ways that are fair and reasonable. The
      list below sets out the ways in which we might use your information and
      the legal basis for that use.
    </p>

    <p>Processing necessary to perform our contract with you:</p>

    <ul>
      <li>
        Provide you with the BujinTV service (including creating an account for
        you, enabling you to subscribe for our service and processing payments
        from you)
      </li>
      <li>
        Provide you with technical support and service messages, for example to
        let you know when a new version of our app is available
      </li>
      <li>
        Administer prize draws or competitions which you choose to enter,
        including (where required) publishing the name of winners
      </li>
      <li>Assist you with any customer services enquiries you make</li>
    </ul>

    <p>Processing based on our or a third party’s legitimate interests:</p>

    <ul>
      <li>
        Personalise our service and our promotional material for you, for
        example to recommend content we think you might like - this will usually
        be based on your browsing and viewing behaviour, any may also be based
        on any demographic information we hold about you
      </li>
      <li>
        Understanding and improving your profile so we can provide you with the
        best possible service (which may include sharing your information with
        carefully selected third parties so they can match your profile with
        other information lawfully obtained by them)
      </li>
      <li>
        Understanding user journeys to and on our website and in our apps to
        determine whether users complete registration (and so we can remind you
        to complete your registration journey)
      </li>
      <li>
        Understanding your location so we can ensure that our service is only
        made available in our territory of operation
      </li>
      <li>
        Send you marketing information, including content recommendations and
        special offers in accordance with your marketing preferences, and to
        manage our email suppression lists (see our section below on marketing
        for further information)
      </li>
      <li>To invite you to participate in surveys or user research</li>
      <li>
        Analyse your use of our service to help us understand how people use
        BujinTV, and to improve them for everyone
      </li>
      <li>Enforce our rights and our terms of service</li>
      <li>
        Prevent fraud or other potentially illegal activities (including
        copyright infringement)
      </li>
      <li>
        Sharing your information with third parties to allow us to promote our
        service to you on their platform
      </li>
      <li>
        Validate your entitlement to any special offer or voucher code (and we
        may share information with relevant third parties for this purpose)
      </li>
    </ul>

    <p>
      If we want to use your data in any way not covered in the list above, we
      will only do so with your consent.
    </p>

    <p>
      Aggregate and/or Anonymised Information: We may aggregate or anonymise
      certain information that we store from you. Aggregate and de-identified
      information is designed so that we can’t identify you personally.
    </p>

    <h2>DIRECT MARKETING MESSAGES</h2>

    <p>
      We will only send you direct marketing about BujinTV products and
      services, unless you indicated during the registration process that you do
      not wish to receive such messages by sending us an email from the email
      address you use to register to optout@bujin.tv. In addition, you can
      unsubscribe from receiving such messages by clicking the unsubscribe link
      in any of our emails or in the account section of our website.
    </p>

    <p>
      We may from time to time ask you if you are happy to receive marketing
      information from 3rd parties. If you opt-in to receive such information,
      we will share your email address with the relevant parties who may use it
      to contact you with marketing. Please note that such parties act as data
      controllers in relation to use of your data and this Privacy Notice does
      not apply to such processing.
    </p>

    <h2>WHEN WE MAY SHARE YOUR INFORMATION WITH OTHERS</h2>

    <p>
      We won’t sell or rent your data to others. We may share your information
      outside BujinTV in the following circumstances:
    </p>

    <ul>
      <li>
        Our service providers: We use a number of third parties to perform
        business functions on our behalf, such as sending our newsletters,
        hosting our online services and providing our customer services. We only
        disclose the information necessary to enable these third parties to
        perform their services. Our suppliers are contracted to comply with our
        instructions and we require that they don’t use your personal
        information for their own business purposes.
      </li>
      <li>
        Payment partners: We use specialist payment partners to process your
        payment card information. In processing your payments, they may also use
        the data for fraud detection and prevention. They’re obliged to remain
        certified as compliant with the Payment Card Industry (PCI) security
        standards.
      </li>
      <li>
        Voucher code partners: We may share your information as necessary with
        any third parties where you have received a voucher code which is only
        available to a particular group of people (for example, if we provide a
        voucher code to employees of a particular organisation, we may share
        your information with that organisation to validate your entitlement to
        that voucher code).
      </li>
      <li>
        Social media networks and other third parties: We (or a media agency
        acting on our behalf) may share your information with social media
        networks and other carefully selected third parties for the purpose of
        promoting our service to you, and other people like you, on their
        platforms. Where appropriate this is done by hashing or encrypting your
        information before it is sent to them, after which they can identify
        whether you are also a user of their platform. To opt-out from having
        your information used for this purpose please adjust your marketing
        preferences.
      </li>
      <li>
        Regulatory bodies and others: We may disclose your personal information
        to the police, regulatory bodies or legal advisers in connection with
        any alleged criminal offence, unlawful activity or suspected breach of
        the Terms of Use and/or the breach of other terms and conditions or
        otherwise where required by law or where we suspect harm or potential
        harm to others. We will cooperate with any law enforcement authorities
        or court order requesting or directing us to disclose the identity or
        location of or any other information about anyone breaching any relevant
        terms and conditions or otherwise for the prevention or detection of
        crime or the apprehension or prosecution of offenders.
      </li>
      <li>
        Sale or transfer of all or part of our business or assets: If we decide
        to change or restructure our business we may need to pass your personal
        information to one of our group companies so we can continue to
        effectively deliver the BujinTV service to you.
      </li>

      <li>
        Also, if we become involved in a merger, acquisition, restructuring,
        reorganisation or other transaction involving the sale of some or all of
        our group companies’ assets, then your information may be included in
        the assets that are transferred to the new owner and may be provided to
        the entities and advisors involved. Such a transaction may involve us:
        (i) retaining the right to continue to use transferred personal
        information in addition to the right of the new owner to use such
        information; and (ii) engaging in additional transfers of personal
        information (including new personal information) with the new owner from
        time to time following such a transaction.
      </li>

      <li>
        If there are changes to our business (such as a re-organisation or
        restructuring), your personal information will remain subject to this
        privacy notice (as amended from time to time). However, where your
        personal information is transferred to a new owner following a merger or
        acquisition it may be subject to a different privacy notice. We, or the
        new owner, will provide notice to you before any of your personal
        information becomes subject to a different privacy notice.
      </li>
    </ul>

    <h2>SERVICE PROVIDERS BASED OUTSIDE EUROPE</h2>

    <p>
      We use certain third party companies to perform some functions of the
      BujinTV service on our behalf or to provide services to us. These third
      parties are only allowed access to your information as needed to perform
      such functions or to provide such services. They’re not allowed to use it
      for any other purpose.
    </p>

    <p>
      Some of these third party companies are located, or have servers that are
      located, outside the European Economic Area. Where we share your
      information with such companies, we take all steps reasonably necessary to
      ensure your information is treated securely and in accordance with this
      privacy notice.
    </p>

    <p>
      However, please note that the data protection and other laws of such
      countries may not be as comprehensive as those in the European Economic
      Area. We’ll only transfer your personal information to a territory outside
      of the European Economic Area under the following circumstances:
    </p>

    <ul>
      <li>
        the European Commission has determined that an adequate level of
        protection for personal information is in place in the country to which
        we transfer your data
      </li>
      <li>
        standard data protection clauses as adopted by the European Commission
        are in place governing the transfer (you can view the standard clauses
        on the European Union’s legal website at eur-lex.europa.eu)
      </li>
      <li>
        for transfers to the USA, the recipient organisation is a participant in
        the EU-US Privacy Shield programme; or
      </li>
      <li>
        other appropriate safeguards have been put in place, as described in the
        privacy notice for the applicable Service
      </li>
    </ul>

    <h2>YOUR RIGHTS</h2>

    <p>
      Any personal information you supply will be treated in accordance with
      applicable data protection laws including the General Data Protection
      Regulation (the “GDPR”) and any other applicable or superseding
      legislation. We are committed to delivering the rights that individuals
      are entitled to under data protection laws in the UK. These are:
    </p>

    <ul>
      <li>
        the right to request a copy of the personal information we hold about
        you. To do this, please contact us at privacy@bujinmedia.com making
        clear that you are requesting a copy of your personal information and
        including full details of what you require. You may also be required to
        submit a proof of your identity.
      </li>
      <li>
        the right to object to your personal information being used for direct
        marketing. Please see the section above on marketing for details of how
        to opt out.
      </li>
      <li>
        the right to object to your personal information being processed where
        the legal basis for the processing is our own legitimate interests as a
        business. We will comply with such a request unless there is a lawful
        reason for not doing so, such as when we need to continue to process
        your information to defend a legal claim or if this information is
        necessary for the ongoing provision of our service.
      </li>
      <li>
        the right to rectification. You may request that we correct any
        inaccurate and/or complete any incomplete personal information.
      </li>
      <li>
        the right to withdraw consent. Where we are processing your personal
        information on the basis that you have given us your consent to do so,
        you may withdraw your consent at any time.
      </li>
      <li>
        the right of erasure. You may request that we erase your personal
        information and we will comply, unless there is a lawful reason for not
        doing so. For example, there may be an overriding legitimate ground for
        retaining your personal information, such as keeping your payment
        information for several years to comply with HMRC rules.
      </li>
      <li>
        the right to data portability. In certain circumstances, you may request
        that we provide your personal information to you in a structured,
        commonly used and machine readable format and have it transferred to
        another provider of the same or similar services to us. Where this right
        is applicable, we will comply with such transfer as far as it is
        technically feasible.
      </li>
      <li>
        the right to lodge a complaint with the supervisory authority. We
        suggest that you contact us about any questions or if you have a
        complaint in relation to how we process your personal information.
        However, you do have the right to contact the relevant supervisory
        authority directly. To contact the Information Commissioner’s Office in
        the United Kingdom, please visit the ICO website for instructions.
      </li>
    </ul>

    <h2>HOW LONG WE KEEP YOUR INFORMATION</h2>

    <p>
      We’ll keep your information for as long as you use our services and for a
      period of time after you have stopped using the services for analysis and
      administrative purposes. We may keep an anonymised form of your account
      for a longer period, but it won’t be possible to link this data back to
      you.
    </p>

    <p>
      Our payment partners will keep certain payment information for as long as
      required by law; for example for VAT reporting, they may keep your payment
      card number and part of your address (as evidence of where our services
      were consumed).
    </p>

    <h2>PERSONS UNDER 18</h2>

    <p>You must be 18 years old or older to create an account with BujinTV.</p>

    <p>
      If you’re the parent or guardian of a child who’s aged under 18, and you
      become aware that your child has provided us with information without your
      consent, please contact us at privacy@bujinmedia.com. We’ll delete their
      information from our records within a reasonable time.
    </p>

    <h2>UPDATES TO THIS POLICY</h2>

    <p>
      This Privacy Notice may be updated from time to time to reflect changes in
      law or best practice, or changes in our business practices. Material
      changes will be clearly highlighted and we’ll take reasonable steps to
      notify you of any changes that impact on your privacy. The date of the
      most recent revision will appear at the top of this page. You should check
      this policy each time you provide information to us.
    </p>

    <h2>DATA CONTROLLER</h2>

    <p>
      Bujin Media LLP is a company incorporated in England and Wales (registered
      number OC433499). Our registered office is 61 Bridge Street, Kington,
      Herefordshire, HR5 3DJ U.K.
    </p>

    <h2>COOKIES AND SIMILAR TECHNOLOGIES</h2>

    <p>
      Like most companies, we (and our service providers) use specialist web and
      app technologies. This notice provides general information about cookies
      and describes how these may be used on BujinTV services.
    </p>

    <h3>Why does BujinTV use these technologies?</h3>

    <p>We use cookies for a variety of purposes, as described below.</p>

    <ul>
      <li>
        Operating our services (strictly necessary cookies): We use strictly
        necessary cookies and other similar technologies to keep you signed in,
        to secure our services and your information and for other system
        administration purposes. Disabling these cookies will result in loss of
        functionality and may prevent you from being able to access the service.
        These technologies also allow us to understand how users interact with
        our service so we can make sure your BujinTV experience is as good as
        possible and to help us improve our service in the future. These cookies
        store information about your use of our services, as well as technical
        information such as your IP address, time of visit, whether you are a
        return visit, any referring website and other information about your use
        of our service. These analytical services help us to know how many users
        we have, which parts of our sites are most popular, what browsers are
        used (so we can maximise compatibility) and the country or region where
        our users are located. This enables us to better understand who is using
        our site and to ensure we are reaching our target demographic, and to
        improve and tailor our services accordingly.
      </li>
      <li>
        Performance Cookies: We record visits to our website and the source of
        those visits, and which allows us to manage our use of other types of
        cookies such as the Targeting Cookies described below
      </li>
      <li>
        Targeting Cookies for Online Advertising: we may also use advertising
        companies or agencies to serve our online marketing to you elsewhere on
        the web. These companies use tracking technologies to store information
        about your use of our services which they use to target our marketing
        messages to you, to make sure you don’t see advertising about BujinTV
        once you have subscribed, and to understand how you learned about
        BujinTV. They may also store information about your online activities
        over time and across different websites and other online services so
        that they can better understand your interests to serve you with
        relevant adverts. Please note that if you do not give us consent to set
        these cookies, you may still see BujinTV advertising, but it will not be
        personalised to you.
      </li>
      <li>
        Personalisation: we may use cookies and other technologies to
        personalise content based on your viewing and browsing behaviour.
      </li>
      <li>
        Apps: Our apps store the advertising identifier provided by your
        operating system (i.e. iOS or Android). You can learn how to opt out of
        mobile app tracking by certain advertising networks through the privacy
        and/or advertising settings on your device. To find out how to change
        these settings for Apple or Android devices, see: <br />
        Apple:
        <a href="https://support.apple.com/en-gb/HT202074"
          >https://support.apple.com/en-gb/HT202074</a
        >
        <br />
        Android:
        <a href="http://www.google.co.uk/policies/technologies/ad"
          >http://www.google.co.uk/policies/technologies/ad</a
        ><br />
      </li>

      <li>
        Browser settings: Many web browsers automatically accept cookies (and
        LSO’s and HTML5 storage), but you can usually modify your browser’s
        setting to decline cookies if you prefer or delete them when you close
        your browser; you’ll find instructions for this on your browser’s help
        menu. If you choose to decline cookies, certain features of our services
        may not function properly or become inaccessible to you. In addition,
        you may also render some technologies unusable by rejecting or removing
        their associated cookies. Note that if you choose to remove cookies, you
        may remove opt- out cookies that affect your online marketing
        preferences.
      </li>
    </ul>

    <h3>Contact Us</h3>

    <p>
      If you have any questions or concerns about cookies used by BujinTV please
      contact us at: privacy@bujinmedia.com
    </p>

    <copyright-disclaimer></copyright-disclaimer>
  </static-page>
</template>

<script>
import StaticPage from "@/components/StaticPage";
import CopyrightDisclaimer from "@/components/CopyrightDisclaimer";

export default {
  name: "privacy-policy",

  components: { CopyrightDisclaimer, StaticPage },
};
</script>
